// Default variables.
@import 'default-variables';

// Bootstrap Framework.
@import '../bootstrap/assets/stylesheets/bootstrap';

// Base-theme overrides.
@import 'overrides';

.status-0 {
  @extend .label;
  @extend .label-success;
}

.status-1 {
  @extend .label;
  @extend .label-info;
}

.status-3 {
  @extend .label;
  @extend .label-warning;
}

.status-4 {
  @extend .label;
  @extend .label-danger;
}

.field-name-field-femm-image {
  img {
    margin-left: auto;
    margin-right: auto;
  }
}

.page-node-add-simulation, .node-type-simulation,
.page-node-add-optiafpm-tool, .node-type-optiafpm-tool,
.page-node-add-userafpm-tool, .node-type-userafpm-tool {
  .action-links {
    li {
      a {
        @extend .btn;
        @extend .btn-primary;
      }
      float: right;
    }
  }
  .region-content {
    .field-group-fieldset {
      + .col-lg-6:nth-of-type(2n+1) {
        clear: both;
        // border-color: green;
      }
      padding: 0px;
    }
  }
  .node-form {
    fieldset.col-lg-4:nth-of-type(3n+1) {
      clear: both;
      padding: 0px;
      // border-color: green;
    }
    .form-type-textfield {
      input {
        width: 80%;
        display: inline;
        margin-right: 5px;
      }
    }
    .field-name-field-json-result,
    .field-name-field-variable-file,
    .field-name-field-femm-image,
    .field-name-field-duration,
    .field-name-field-simulation-messages {
      display: none;
    }
  }
  .tabs-left {
    clear: both;
  }
}

.resp-center {
  margin-left: auto;
  margin-right: auto;
}

.comment {
  h3 {
    display: none;
  }
}

.views-field-created, .views-field-field-simulation-status, .views-field-field-duration {
  white-space: nowrap;
}
